import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Article, Paragraph, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';

export const VMWareCreateApplication: React.FC<
  Readonly<ConnectionStepProps>
> = ({ appName, onNextStep }) => (
  <Article>
    <Title>Step 1: Create a new SaaS Application</Title>

    <Paragraph>In your Workspace ONE Catalog, click "New".</Paragraph>

    <Img priority height={740} src="/sso/vmware/v1/vmware-1.png" width={1278} />

    <Paragraph>
      Provide a Name that describes {appName}, then click "Next".
    </Paragraph>

    <Confirm label="I’ve created a new SaaS Application" onClick={onNextStep} />
  </Article>
);

export const VMWareBasicConfiguration: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title>Step 2: Basic SAML Configuration</Title>

    <Paragraph>Click the "Configuration" tab from the left sidebar.</Paragraph>

    <CopyInput
      label="Copy this URL/XML"
      value={connection?.saml_sp_metadata_url}
    />

    <Paragraph>Submit the "URL/XML".</Paragraph>

    <Img height={740} src="/sso/vmware/v1/vmware-2.png" width={1278} />

    <Confirm
      label="I’ve completed my Basic SAML Configuration."
      onClick={onNextStep}
    />
  </Article>
);

export const VMWareAdvancedConfiguration: React.FC<
  Readonly<ConnectionStepProps>
> = ({ onNextStep }) => (
  <Article>
    <Title>Step 3: Advanced SAML Configuration</Title>

    <Paragraph>Continue scrolling and expand "Advanced Properties".</Paragraph>

    <Img priority height={740} src="/sso/vmware/v1/vmware-3.png" width={1278} />

    <Paragraph>
      Enable "Sign Assertion" and "Include Assertion Signature".
    </Paragraph>

    <Img height={740} src="/sso/vmware/v1/vmware-4.png" width={1278} />

    <Confirm
      label='I’ve enabled "Sign Assertion" and "Include Assertion Signature"'
      onClick={onNextStep}
    />
  </Article>
);

export const VMWareConfigureAttributeMap: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title>Step 4: Configure Attribute Map</Title>

    <Paragraph>Continue scrolling until "Custom Attribute Mapping".</Paragraph>

    <Img priority height={740} src="/sso/vmware/v1/vmware-5.png" width={1278} />

    <Paragraph>
      Fill in the following attribute mappings and select "Next".
    </Paragraph>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Paragraph>
      NOTE: Some VMware configurations use user.ExternalId instead of
      user.objectGUID. In this case, you would map the id attribute to
      user.ExternalId.
    </Paragraph>

    <Img height={740} src="/sso/vmware/v1/vmware-6.png" width={1278} />

    <Confirm label="I’ve configured the Attribute Map" onClick={onNextStep} />
  </Article>
);

export const VMWareUploadCertificate: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, errors, onFileInput, onNextStep }) => (
  <Article>
    <Title>Step 5: Upload X.509 Certificate</Title>

    <Paragraph>
      After saving your SaaS Application, click "Settings" then "SAML Metadata".
    </Paragraph>

    <Img priority height={740} src="/sso/vmware/v1/vmware-7.png" width={1278} />

    <Paragraph>
      Download the “Signing Certificate” and upload it below.
    </Paragraph>

    <Card>
      <Form
        disabled={!connection?.saml_x509_certs}
        isUpdate={!!errors?.saml_x509_certs}
        onSubmit={onNextStep}
      >
        <FileField
          error={errors?.saml_x509_certs}
          filename="Signing Certificate"
          label="Signing Certificate"
          name="saml_x509_certs"
          onUpload={onFileInput}
          value={connection?.saml_x509_certs?.[0]}
        />
      </Form>
    </Card>
  </Article>
);

export const VMWareProvideLaunchUrl: React.FC<
  Readonly<ConnectionStepProps>
> = ({
  connection,
  errors,
  isLoading,
  onInputChange,
  onNextStep,
  validationErrors,
}) => (
  <Article>
    <Title>Step 6: Provide a Launch URL</Title>

    <Paragraph>
      Close the Settings modal and the newly created SaaS Application.
    </Paragraph>

    <Img priority height={740} src="/sso/vmware/v1/vmware-8.png" width={1278} />

    <Card>
      <Form
        disabled={!connection?.saml_idp_url}
        error={errors?.saml_idp_url || validationErrors?.saml_idp_url}
        isInline={true}
        isLoading={isLoading}
        isUpdate={!!errors?.saml_idp_url}
        onSubmit={onNextStep}
      >
        <TextField
          autoFocus={true}
          label="Launch URL"
          name="saml_idp_url"
          onChange={onInputChange}
          placeholder="https://foocorp.workspaceoneaccess.com:443/SAAS/API/1.0/GET/apps/launch/app/40cc1bbd-8931-46fd-9ff2-105757cf25bd"
          value={connection?.saml_idp_url}
        />
      </Form>
    </Card>
  </Article>
);
