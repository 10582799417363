import { Card } from 'components/card';
import { ErrorMessage } from 'components/error-message';
import { FileField } from 'components/fields';
import { Form } from 'components/form';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { graphql } from 'utils/graphql';

interface AdpCertUploadFormProps {
  connectionId: string;
  onNextStep: VoidFunction;
}

export const AdpCertUploadForm: FC<AdpCertUploadFormProps> = ({
  connectionId,
  onNextStep,
}) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [newX509Certificate, setNewX509Certificate] = useState<
    string | undefined
  >();
  const [newAdpSslCertificate, setNewAdpSslCertificate] = useState<
    string | undefined
  >();
  const [newAdpSslPrivateKey, setNewAdpSslPrivateKey] = useState<
    string | undefined
  >();

  useEffect(() => {
    const setUpdateError = () => {
      setError('The given ADP Certificates were invalid. Please try again.');
      setNewAdpSslCertificate(undefined);
      setNewAdpSslPrivateKey(undefined);
    };

    const updateAdpConnection = async () => {
      try {
        const { data } = await graphql().AddAdpConnectionSslCertificate({
          input: {
            connectionId,
            value: newAdpSslCertificate || '',
            privateKey: newAdpSslPrivateKey || '',
          },
        });

        if (
          data &&
          data.portal_addAdpConnectionSslCertificate.__typename ===
            'Portal_AdpConnectionSslCertificateAdded'
        ) {
          setNewX509Certificate(
            data.portal_addAdpConnectionSslCertificate
              .adpConnectionSslCertificate.value,
          );

          return;
        }
      } catch (error) {
        // noop
      }

      setUpdateError();
    };

    if (newAdpSslPrivateKey && newAdpSslCertificate) {
      void updateAdpConnection();
    }
  }, [connectionId, newAdpSslPrivateKey, newAdpSslCertificate]);

  const onAddCert = async (
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    setError(undefined);
    const content = await onFileUpload(event);
    setNewAdpSslCertificate(content);
  };

  const onAddPrivateKey = async (
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    setError(undefined);
    const content = await onFileUpload(event);
    setNewAdpSslPrivateKey(content);
  };

  const onFileUpload = async (
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<string> => {
    if (!event?.target?.files) {
      return '';
    }

    const file = event?.target?.files[0];

    if (!file) {
      return '';
    }

    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.readAsText(file);
      reader.onloadend = () => resolve(reader.result?.toString() ?? '');
    });
  };

  return (
    <>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Card>
        <Form
          disabled={!newX509Certificate || !!error}
          isUpdate={false}
          onSubmit={onNextStep}
        >
          <FileField
            key={newAdpSslCertificate}
            accept=".pem"
            filename="SSL Certificate"
            id="adp-ssl-cert"
            label="Add an ADP SSL Certificate"
            name="newAdpSslCertificate"
            onUpload={onAddCert}
            value={newAdpSslCertificate}
          />

          <FileField
            key={newAdpSslPrivateKey}
            accept=".key"
            filename="SSL Private Key"
            id="adp-private-key"
            label="Add an ADP SSL Private Key"
            name="newAdpSslPrivateKey"
            onUpload={onAddPrivateKey}
            value={newAdpSslPrivateKey}
          />
        </Form>
      </Card>
    </>
  );
};
